export function getCardMarketIdBySetCode(setCode) {
    switch (setCode) {
        case 'mid':
            return 4362;
        case 'ltr':
            return 5285;
        case 'one':
            return 5184;
        case 'akh':
            return 1729;
        case 'kld':
            return 1717;
        case 'dtk':
            return 1601;
        case 'war':
            return 2439;
        case 'ktk':
            return 1495;
        case 'm15':
            return 1485;
        case 'snc':
            return 4975;
        case 'dsk':
            return 5806;
        case 'e01':
            return 1730;
        case 'cma':
            return 1732;
        case 'otj':
            return 5647;
    }
}